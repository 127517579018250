import { useState } from 'react';

import { fromTree, VSpacingContainer } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AdvancedFilter from 'src/components/AdvancedSearchFilters/Magritte/AdvancedFilter';
import CompositeSelection from 'src/components/CompositeSelection';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';

import AreaActivator from 'src/components/AdvancedSearchFilters/Magritte/Region/AreaActivator';

const TrlKeys = {
    fieldTitle: 'employer.resumesSearch.region',
};

const Region: TranslatedComponent = ({ trls }) => {
    const areaTree = useSelector((state) => state.areaTree);
    const selectedAreas = useSelector((state) => state.advancedSearch[CriteriaKey.Area]);

    const collection = fromTree(areaTree);

    const [areaValues, setAreaValues] = useState(() => selectedAreas?.map(String) || []);

    return (
        <AdvancedFilter legend={trls[TrlKeys.fieldTitle]}>
            <CompositeSelection.Root collection={collection} value={areaValues} onChange={setAreaValues}>
                <CompositeSelection.TreeSelectorModal
                    collapseToParentId
                    texts={{
                        title: trls[TrlKeys.fieldTitle],
                    }}
                />
                <VSpacingContainer default={12}>
                    <CompositeSelection.Chips containerDataQa="selected-regions" />
                    <AreaActivator />
                </VSpacingContainer>
            </CompositeSelection.Root>
            {areaValues.map((id) => (
                <input key={id} type="hidden" name={CriteriaKey.Area} value={id} />
            ))}
        </AdvancedFilter>
    );
};

export default translation(Region);
