import Checkbox from 'bloko/blocks/checkbox';
import FormItem from 'bloko/blocks/form/FormItem';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import AdvancedFilter from 'src/components/AdvancedSearchFilters/AdvancedFilter';

const TrlKeys = {
    fieldTitle: 'clusters.suitable.vacancy',
};
interface VacancyProps {
    title?: string;
}

const Vacancy: TranslatedComponent<VacancyProps> = ({ trls, title }) => {
    const vacancy = useSelector(({ advancedSearch }) => advancedSearch.vacancy);
    if (!vacancy) {
        return null;
    }
    const filterTitle = title || trls[TrlKeys.fieldTitle];

    return (
        <AdvancedFilter legend={filterTitle}>
            <FormItem baseline>
                <Checkbox
                    name="vacancy_id"
                    value={vacancy.vacancyId}
                    defaultChecked={true}
                    data-qa="vacancysearch__similar-resume-item"
                >
                    {vacancy.name}
                </Checkbox>
            </FormItem>
        </AdvancedFilter>
    );
};

export default translation(Vacancy);
