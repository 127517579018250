import { Title, VSpacing as MagritteVSpacing } from '@hh.ru/magritte-ui';
import Column from 'bloko/blocks/column';
import { H1 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import MagritteSideBarLayout from 'src/app/layouts/BlokoLayouts/MagritteSideBarLayout';
import PageLayout, { SideBarLayout } from 'src/app/layouts/PageLayout';
import translation from 'src/components/translation';
import useFeatureEnabled from 'src/hooks/useFeatureEnabled';

import AdvancedVacancySearchForm from 'src/pages/AdvancedVacancySearch/components/AdvancedVacancySearchForm';
import MagritteAdvancedVacancySearchForm from 'src/pages/AdvancedVacancySearch/components/MagritteAdvancedVacancySearchForm';
import RightBanners from 'src/pages/AdvancedVacancySearch/components/RightBanners';

const TrlKeys = {
    title: 'vacancySearch.title',
};

const Features = {
    magritteOnAdvancedVacancySearch: 'magritte_on_advanced_vacancy_search',
};

const AdvancedVacancySearch: TranslatedComponent = ({ trls }) => {
    const isMagritte = useFeatureEnabled(Features.magritteOnAdvancedVacancySearch);
    const title = trls[TrlKeys.title];

    if (isMagritte) {
        return (
            <PageLayout title={title} layout={MagritteSideBarLayout} sidebar={RightBanners}>
                <Title size="large" Element="h1">
                    {title}
                </Title>
                <MagritteVSpacing default={40} />
                <MagritteAdvancedVacancySearchForm />
            </PageLayout>
        );
    }

    return (
        <PageLayout title={title} layout={SideBarLayout} sidebar={RightBanners}>
            <Column xs="4" s="8" m="8" l="11">
                <H1>{title}</H1>
                <VSpacing base={6} />
            </Column>
            <AdvancedVacancySearchForm />
        </PageLayout>
    );
};

export default translation(AdvancedVacancySearch);
