import { useCallback, useEffect, useState } from 'react';

import { Action, HSpacingContainer, Link, Text, VSpacingContainer } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import ConversionNumber from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import CompositeSelection from 'src/components/CompositeSelection';
import { useCompositeSelectionContext } from 'src/components/CompositeSelection/contexts';
import translation from 'src/components/translation';

import styles from './styles.less';

const TrlKeys = {
    any: 'resume.advancedSearch.field.professional_role.any',
    youChose: 'resumeSearch.profAreaTree.youChose',
    roleOne: 'resumeSearch.specialization.1',
    roleTwo: 'resumeSearch.specialization.2',
    roleFive: 'resumeSearch.specialization.5',
};

const MAX_SHOW_CHIPS = 10;

interface ActivatorWithChipsProps {
    texts: {
        add: string;
        edit: string;
    };
    activatorDataQa: string;
    chipsDataQa: string;
}

const ActivatorWithChips: TranslatedComponent<ActivatorWithChipsProps> = ({
    texts,
    activatorDataQa,
    chipsDataQa,
    trls,
}) => {
    const [showChips, setShowChips] = useState(false);

    const { value, onChange, setTreeSelectorModalVisibility } = useCompositeSelectionContext();

    useEffect(() => {
        setShowChips(value.length > 0 && value.length <= MAX_SHOW_CHIPS);
    }, [value]);

    const openModal = useCallback(() => {
        setTreeSelectorModalVisibility(true);
    }, [setTreeSelectorModalVisibility]);

    if (value.length === 0) {
        return (
            <HSpacingContainer default={28}>
                <Text>{trls[TrlKeys.any]}</Text>
                <Link Element="button" onClick={openModal} data-qa={activatorDataQa} inline>
                    {texts.add}
                </Link>
            </HSpacingContainer>
        );
    }

    return (
        <VSpacingContainer default={12}>
            {value.length > MAX_SHOW_CHIPS && (
                <HSpacingContainer default={28}>
                    <Text>{trls[TrlKeys.youChose]}</Text>
                    <div className={styles.chipsLink}>
                        <Link Element="button" onClick={() => setShowChips(!showChips)}>
                            <ConversionNumber
                                value={value.length}
                                one={trls[TrlKeys.roleOne]}
                                some={trls[TrlKeys.roleTwo]}
                                many={trls[TrlKeys.roleFive]}
                            />
                        </Link>
                        <Action icon={CrossOutlinedSize16} onClick={() => onChange([])} disablePadding />
                    </div>
                </HSpacingContainer>
            )}
            {showChips && <CompositeSelection.Chips containerDataQa={chipsDataQa} />}
            <Link Element="button" onClick={openModal} data-qa={activatorDataQa}>
                {texts.edit}
            </Link>
        </VSpacingContainer>
    );
};

export default translation(ActivatorWithChips);
