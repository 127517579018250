import { useCallback, useDeferredValue, useRef, useState, useEffect } from 'react';

import {
    Action,
    BottomSheet,
    BottomSheetFooter,
    Button,
    Text,
    Modal,
    NavigationBar,
    TreeSelector,
    ListControls,
    TreeSelectorProps,
    TreeFilter,
} from '@hh.ru/magritte-ui';
import { ChevronLeftOutlinedSize24, CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useCompositeSelectionContext } from 'src/components/CompositeSelection/contexts';
import translation from 'src/components/translation';

interface TreeSelectorModalProps {
    maxSelected?: number;
    leavesOnly?: boolean;
    collapseToParentId?: boolean;
    singleChoice?: boolean;
    suggestedNotFound?: string[];
    constantlySuggested?: string[];
    texts?: {
        title?: string;
        titleDescription?: string;
        submit?: string;
        cancel?: string;
        searchPlaceholder?: string;
        notFound?: string;
        selectAll?: string;
    };
    treeFilter?: TreeFilter;
    renderContentBefore?: (isSearch: boolean) => React.ReactNode;
    onChangeFilterQuery?: (ids: string[], query: string) => void;
}

const TrlKeys = {
    title: 'compositeSelection.treeSelector.title',
    submit: 'compositeSelection.treeSelector.submit',
    cancel: 'compositeSelection.treeSelector.cancel',
    searchPlaceholder: 'compositeSelection.treeSelector.searchPlaceholder',
    notFound: 'compositeSelection.treeSelector.notFound',
    selectAll: 'compositeSelection.treeSelector.selectAll',
};

const CompositeSelectionTreeSelectorModal: TranslatedComponent<TreeSelectorModalProps> = (props) => {
    const { collection, value, isTreeSelectorModalVisible, setTreeSelectorModalVisibility, onChange } =
        useCompositeSelectionContext();

    const {
        maxSelected,
        leavesOnly,
        singleChoice,
        collapseToParentId,
        suggestedNotFound,
        constantlySuggested,
        trls,
        treeFilter,
        renderContentBefore,
        onChangeFilterQuery,
    } = props;

    const deferredVisible = useDeferredValue(isTreeSelectorModalVisible);

    const texts = {
        title: props?.texts?.title || trls[TrlKeys.title],
        titleDescription: props?.texts?.titleDescription,
        submit: props?.texts?.submit || trls[TrlKeys.submit],
        cancel: props?.texts?.cancel || trls[TrlKeys.cancel],
        searchPlaceholder: props?.texts?.searchPlaceholder || trls[TrlKeys.searchPlaceholder],
        notFound: props?.texts?.notFound || trls[TrlKeys.notFound],
        selectAll: props?.texts?.selectAll || trls[TrlKeys.selectAll],
    };

    const [selected, setSelected] = useState<string[]>(value);
    const controlsRef = useRef<ListControls>(null);
    const [mobileTitle, setMobileTitle] = useState<string | undefined>(texts.title);

    useEffect(() => {
        if (!isTreeSelectorModalVisible) {
            setSelected(value);
            setMobileTitle(undefined);
        }
    }, [value, isTreeSelectorModalVisible]);

    const handleChange: TreeSelectorProps<never>['onChange'] = useCallback((allSelected) => {
        setSelected(allSelected);
    }, []);

    const handleCloseModal = useCallback(() => {
        setTreeSelectorModalVisibility(false);
    }, [setTreeSelectorModalVisibility]);

    const handleSaveAndCloseModal = useCallback(() => {
        onChange(selected);

        handleCloseModal();
    }, [selected, handleCloseModal, onChange]);

    const handleMobileNavigationChange: NonNullable<TreeSelectorProps<never>['onMobileNavigationChange']> = useCallback(
        (id) => {
            const text = id ? collection.getModel(id)?.text : undefined;
            setMobileTitle(text);
        },
        [collection]
    );

    if (!isTreeSelectorModalVisible) {
        return null;
    }

    return (
        <TreeSelector
            collection={collection}
            value={selected}
            ref={controlsRef}
            maxSelected={maxSelected}
            leavesOnly={leavesOnly}
            collapseToParentId={collapseToParentId}
            singleChoice={singleChoice}
            suggestedNotFound={suggestedNotFound}
            constantlySuggested={constantlySuggested}
            contentNotFound={<Text typography="paragraph-2-regular">{texts.notFound}</Text>}
            renderContentBefore={renderContentBefore}
            treeFilter={treeFilter}
            getSelectAllParentTrl={() => texts.selectAll}
            onChangeFilterQuery={onChangeFilterQuery}
            onMobileNavigationChange={handleMobileNavigationChange}
            onChange={handleChange}
        >
            {({ renderTreeSelector, renderInput }) => {
                return (
                    <>
                        <Modal
                            visible={deferredVisible}
                            title={texts.title}
                            titleSize="large"
                            titleDescription={texts.titleDescription}
                            titleDescriptionStyle="secondary"
                            actions={
                                <Action
                                    style="neutral"
                                    mode="secondary"
                                    icon={CrossOutlinedSize24}
                                    onClick={handleCloseModal}
                                />
                            }
                            options={renderInput({ placeholder: texts.searchPlaceholder })}
                            onClose={handleCloseModal}
                            leftButtons={
                                <Button
                                    data-qa="composite-selection-tree-selector-modal-cancel"
                                    mode="secondary"
                                    style="accent"
                                    onClick={handleCloseModal}
                                >
                                    {texts.cancel}
                                </Button>
                            }
                            rightButtons={
                                <Button
                                    data-qa="composite-selection-tree-selector-modal-submit"
                                    mode="primary"
                                    style="accent"
                                    onClick={handleSaveAndCloseModal}
                                >
                                    {texts.submit}
                                </Button>
                            }
                        >
                            {renderTreeSelector()}
                        </Modal>
                        <BottomSheet
                            visible={deferredVisible}
                            height="full-screen"
                            interceptClickHandlers={false}
                            header={
                                <NavigationBar
                                    title={mobileTitle || texts.title}
                                    right={<Action icon={CrossOutlinedSize24} onClick={handleCloseModal} />}
                                    left={
                                        mobileTitle ? (
                                            <Action
                                                icon={ChevronLeftOutlinedSize24}
                                                onClick={() => controlsRef.current?.back()}
                                            />
                                        ) : undefined
                                    }
                                    options={renderInput({ placeholder: texts.searchPlaceholder })}
                                />
                            }
                            footer={
                                <BottomSheetFooter>
                                    <Button
                                        data-qa="composite-selection-tree-selector-bottom-sheet-submit"
                                        mode="primary"
                                        style="accent"
                                        onClick={handleSaveAndCloseModal}
                                        stretched
                                    >
                                        {texts.submit}
                                    </Button>
                                    <Button
                                        data-qa="composite-selection-tree-selector-bottom-sheet-cancel"
                                        mode="secondary"
                                        style="accent"
                                        onClick={handleCloseModal}
                                        stretched
                                    >
                                        {texts.cancel}
                                    </Button>
                                </BottomSheetFooter>
                            }
                            onClose={handleCloseModal}
                        >
                            {renderTreeSelector()}
                        </BottomSheet>
                    </>
                );
            }}
        </TreeSelector>
    );
};

export default translation(CompositeSelectionTreeSelectorModal);
