import { FC, useRef } from 'react';

import paths from 'src/app/routePaths';
import HhtmFromHiddenInput from 'src/components/AdvancedSearchFilters/HhtmFromHiddenInput';
import Company from 'src/components/AdvancedSearchFilters/Magritte/Company';
import Industry from 'src/components/AdvancedSearchFilters/Magritte/Industry';
import ProfessionalRole from 'src/components/AdvancedSearchFilters/Magritte/ProfessionalRole';
import Region from 'src/components/AdvancedSearchFilters/Magritte/Region';
import Resume from 'src/components/AdvancedSearchFilters/Magritte/Resume';
import Vacancy from 'src/components/AdvancedSearchFilters/Magritte/Vacancy';
import VacancySearchExcludedText from 'src/components/AdvancedSearchFilters/Magritte/VacancySearchExcludedText';
import VacancySearchText from 'src/components/AdvancedSearchFilters/Magritte/VacancySearchText';
import SubmitButton from 'src/components/AdvancedSearchFilters/SubmitButton';
import VacancyHiddenInputs from 'src/components/AdvancedSearchFilters/VacancyHiddenInputs';
import Form from 'src/components/Form';

const AdvancedVacancySearch: FC = () => {
    const formRef = useRef<HTMLFormElement>(null);

    return (
        <Form ref={formRef} action={paths.vacancySearch} method="GET" data-qa="advanced-vacancy-search__form">
            <Company />
            <Vacancy />
            <Resume />
            <VacancySearchText />
            <VacancySearchExcludedText />
            <ProfessionalRole />
            <Industry />
            <Region />
            {/* TODO: поменять на магриттовскую, блоковская добавлена для удобства тестирования части формы */}
            <SubmitButton />
            <VacancyHiddenInputs />
            <HhtmFromHiddenInput />
        </Form>
    );
};

export default AdvancedVacancySearch;
