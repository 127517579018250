import { FC, ReactNode, PropsWithChildren } from 'react';

import Checkbox from 'bloko/blocks/checkbox';
import { FormItem } from 'bloko/blocks/form';
import Radio from 'bloko/blocks/radio';

import AdvancedFilter from 'src/components/AdvancedSearchFilters/AdvancedFilter';

import styles from './regularFilter.less';

export enum InputType {
    Radio = 'radio',
    Checkbox = 'checkbox',
}

interface RegularFilterProps {
    name: string;
    legend: string;
    inputs: string[];
    inputNames: Record<string, string>;
    selectedValue: string | string[];
    type?: InputType;
    additional?: Record<string, ReactNode>;
    advancedItem?: ReactNode;
}

const RegularFilter: FC<RegularFilterProps & PropsWithChildren> = ({
    type = InputType.Checkbox,
    name,
    legend,
    inputs,
    inputNames,
    selectedValue,
    additional,
    advancedItem = null,
}) => {
    const Input = type === InputType.Checkbox ? Checkbox : Radio;
    if (!inputs) {
        return null;
    }
    const isChecked = (value: string, selectedValue: string[] | string): boolean =>
        type === InputType.Checkbox ? selectedValue.includes(value) : selectedValue === value;

    return (
        <AdvancedFilter legend={legend}>
            {inputs.map((value) => {
                return (
                    <FormItem key={value} baseline>
                        <Input
                            name={name}
                            value={value}
                            defaultChecked={isChecked(value, selectedValue)}
                            data-qa={`advanced-search__${name}-item_${value}`}
                            labelProps={{ 'data-qa': `advanced-search__${name}-item-label_${value}` }}
                        >
                            {inputNames[value]}
                        </Input>
                        {additional?.[value] && <div className={styles.additional}>{additional?.[value]}</div>}
                    </FormItem>
                );
            })}
            {advancedItem}
        </AdvancedFilter>
    );
};

export default RegularFilter;
