import { Cell, CellText, Checkbox } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AdvancedFilter from 'src/components/AdvancedSearchFilters/Magritte/AdvancedFilter';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    fieldTitle: 'searchvacancy.clusters.company',
};

const Company: TranslatedComponent = ({ trls }) => {
    const company = useSelector(({ advancedSearch }) => advancedSearch.company);

    if (!company) {
        return null;
    }

    const { id, name } = company;

    return (
        <AdvancedFilter legend={trls[TrlKeys.fieldTitle]}>
            <Cell
                align="center"
                left={<Checkbox name="employer_id" value={id} defaultChecked data-qa="vacancysearch__company-item" />}
            >
                <CellText>{name}</CellText>
            </Cell>
        </AdvancedFilter>
    );
};

export default translation(Company);
