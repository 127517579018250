import { FC } from 'react';

import { useSelector } from 'src/hooks/useSelector';

const HhtmFromHiddenInput: FC = () => {
    const hhtmSource = useSelector((state) => state.analyticsParams.hhtmSource);

    return <input type="hidden" name="hhtmFrom" value={hhtmSource} />;
};

export default HhtmFromHiddenInput;
