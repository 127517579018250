import { useMemo, useState } from 'react';

import { filterUniqueLeavesOnly, fromTree } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ActivatorWithChips from 'src/components/AdvancedSearchFilters/Magritte/ActivatorWithChips';
import AdvancedFilter from 'src/components/AdvancedSearchFilters/Magritte/AdvancedFilter';
import CompositeSelection from 'src/components/CompositeSelection';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';

const TrlKeys = {
    fieldTitle: 'resumeBuilder.industry',
    add: 'vacancy.search.industry.select',
    edit: 'vacancy.search.industry.select.full',
};

const Industry: TranslatedComponent = ({ trls }) => {
    const industryTree = useSelector((state) => state.industriesTree);
    const selectedValues = useSelector(
        ({ advancedVacancySearch }) => advancedVacancySearch.selectedValues[CriteriaKey.Industry]
    );

    const [checkedValues, setCheckedValues] = useState<string[]>(selectedValues || []);

    const collection = useMemo(() => fromTree(industryTree), [industryTree]);

    return (
        <AdvancedFilter legend={trls[TrlKeys.fieldTitle]}>
            <CompositeSelection.Root collection={collection} value={checkedValues} onChange={setCheckedValues}>
                <CompositeSelection.TreeSelectorModal
                    collapseToParentId
                    texts={{
                        title: trls[TrlKeys.fieldTitle],
                    }}
                    treeFilter={filterUniqueLeavesOnly}
                />
                <ActivatorWithChips
                    texts={{
                        add: trls[TrlKeys.add],
                        edit: trls[TrlKeys.edit],
                    }}
                    activatorDataQa="industry-addFromList"
                    chipsDataQa="selected-industries"
                />
            </CompositeSelection.Root>
            {checkedValues.map((id) => (
                <input key={id} type="hidden" name={CriteriaKey.Industry} value={id} />
            ))}
        </AdvancedFilter>
    );
};

export default translation(Industry);
