import { useRef } from 'react';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import paths from 'src/app/routePaths';
import AcceptTemporary from 'src/components/AdvancedSearchFilters/AcceptTemporary';
import BOSearchPeriod from 'src/components/AdvancedSearchFilters/BOSearchPeriod';
import BOStatistics from 'src/components/AdvancedSearchFilters/BOStatistics';
import Company from 'src/components/AdvancedSearchFilters/Company';
import HhtmFromHiddenInput from 'src/components/AdvancedSearchFilters/HhtmFromHiddenInput';
import Income from 'src/components/AdvancedSearchFilters/Income';
import Industry from 'src/components/AdvancedSearchFilters/Industry';
import ProfessionalRole from 'src/components/AdvancedSearchFilters/ProfessionalRole';
import Region from 'src/components/AdvancedSearchFilters/Region';
import RegularFilter, { InputType } from 'src/components/AdvancedSearchFilters/RegularFilter';
import Resume from 'src/components/AdvancedSearchFilters/Resume';
import SubmitButton from 'src/components/AdvancedSearchFilters/SubmitButton';
import Vacancy from 'src/components/AdvancedSearchFilters/Vacancy';
import VacancyHiddenInputs from 'src/components/AdvancedSearchFilters/VacancyHiddenInputs';
import VacancySearchExcludedText from 'src/components/AdvancedSearchFilters/VacancySearchExcludedText';
import VacancySearchText from 'src/components/AdvancedSearchFilters/VacancySearchText';
import Form from 'src/components/Form';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { CriteriaKey, ExperienceOption, OrderByOption, PartTimeOption } from 'src/models/search/searchCriteria.types';
import { UserType } from 'src/models/userType';
import { EducationOption, ItemsOnPageOption, LabelOption, SearchPeriodOption } from 'src/models/vacancySearchCriteria';
import { EmploymentLower } from 'src/utils/constants/employment';
import { ScheduleCamelCase } from 'src/utils/constants/schedule';

const TrlKeys = {
    vacancyTitle: 'clusters.similar.vacancy',
    legends: {
        [CriteriaKey.Education]: 'clusters.educationLevel',
        [CriteriaKey.Experience]: 'vacancyInfo.workExperience',
        [CriteriaKey.Employment]: 'search.employment',
        [CriteriaKey.Schedule]: 'search.schedule',
        [CriteriaKey.Label]: 'novafilters.exclusion',
        [CriteriaKey.OrderBy]: 'search.order',
        [CriteriaKey.SearchPeriod]: 'search.period',
        [CriteriaKey.ItemsOnPage]: 'searchForm.label.results.count',
        [CriteriaKey.PartTime]: 'cluster.partTime',
        searchSettings: 'vacancySearch.stat.groups',
    },
    filtersFromDictionaries: {
        [CriteriaKey.Education]: {
            [EducationOption.Higher]: 'searchvacancy.education.higher',
            [EducationOption.NotRequiredNotSpecified]: 'searchvacancy.education.not_required_or_not_specified',
            [EducationOption.SpecialSecondary]: 'searchvacancy.education.special_secondary',
        },
        [CriteriaKey.Experience]: {
            doesNotMatter: 'vacancySearch.experience.doesNotMatter',
            [ExperienceOption.NoExperience]: 'vacancySearch.experience.noExperience',
            [ExperienceOption.Between1And3]: 'vacancySearch.experience.between1And3',
            [ExperienceOption.Between3And6]: 'vacancySearch.experience.between3And6',
            [ExperienceOption.MoreThan6]: 'vacancySearch.experience.moreThan6',
        },
        [CriteriaKey.Employment]: {
            [EmploymentLower.Full]: 'search.employment.full',
            [EmploymentLower.Part]: 'search.employment.part',
            [EmploymentLower.Project]: 'search.employment.project',
            [EmploymentLower.Volunteer]: 'search.employment.volunteer',
            [EmploymentLower.Probation]: 'search.employment.probation',
        },
        [CriteriaKey.PartTime]: {
            [PartTimeOption.EmploymentProject]: 'partTime.employment_project',
            [PartTimeOption.EmploymentPart]: 'partTime.employment_part',
            [PartTimeOption.TemporaryJobTrue]: 'partTime.temporary_job_true',
            [PartTimeOption.FromFourToSixHoursInADay]: 'partTime.from_four_to_six_hours_in_a_day',
            [PartTimeOption.OnlySaturdayAndSunday]: 'partTime.only_saturday_and_sunday',
            [PartTimeOption.StartAfterSixteen]: 'partTime.start_after_sixteen',
        },
        [CriteriaKey.Schedule]: {
            [ScheduleCamelCase.FullDay]: 'search.schedule.fullDay',
            [ScheduleCamelCase.Shift]: 'search.schedule.shift',
            [ScheduleCamelCase.Flexible]: 'search.schedule.flexible',
            [ScheduleCamelCase.Remote]: 'search.schedule.remote',
            [ScheduleCamelCase.FlyInFlyOut]: 'search.schedule.flyInFlyOut',
        },
        [CriteriaKey.Label]: {
            [LabelOption.AcceptHandicapped]: 'advancedResumeSearch.label.accept_handicapped',
            [LabelOption.AcceptKids]: 'advancedResumeSearch.label.accept_kids',
            [LabelOption.AccreditedIT]: 'advancedResumeSearch.label.accredited_it',
            [LabelOption.LowPerformance]: 'advancedResumeSearch.label.low_performance',
            [LabelOption.NotFromAgency]: 'advancedResumeSearch.label.not_from_agency',
            [LabelOption.WithAddress]: 'advancedResumeSearch.label.with_address',
        },
        [CriteriaKey.OrderBy]: {
            [OrderByOption.Relevance]: 'search.order.relevance',
            [OrderByOption.PublicationTime]: 'search.order.publication_time',
            [OrderByOption.SalaryDesc]: 'search.order.salary_desc',
            [OrderByOption.SalaryAsc]: 'search.order.salary_asc',
        },
        [CriteriaKey.SearchPeriod]: {
            [SearchPeriodOption.AllTime]: 'search.period.0',
            [SearchPeriodOption.Month]: 'search.period.30',
            [SearchPeriodOption.Week]: 'search.period.7',
            [SearchPeriodOption.ThreeDays]: 'search.period.3',
            [SearchPeriodOption.Day]: 'search.period.1',
        },
        [CriteriaKey.ItemsOnPage]: {
            [ItemsOnPageOption.Twenty]: 'statistics.global.vacancy.many',
            [ItemsOnPageOption.Fifty]: 'statistics.global.vacancy.many',
            [ItemsOnPageOption.Hundred]: 'statistics.global.vacancy.many',
        },
    },
};

const AdvancedVacancySearch: TranslatedComponent = ({ trls }) => {
    const formRef = useRef<HTMLFormElement>(null);
    const educationDictionary = useSelector((state) => state.vacancySearchDictionaries[CriteriaKey.Education]);
    const experienceDictionary = useSelector((state) => state.vacancySearchDictionaries[CriteriaKey.Experience]);
    const employmentDictionary = useSelector((state) => state.vacancySearchDictionaries[CriteriaKey.Employment]);
    const scheduleDictionary = useSelector((state) => state.vacancySearchDictionaries[CriteriaKey.Schedule]);
    const labelDictionary = useSelector((state) => state.vacancySearchDictionaries.labels);
    const orderByDictionary = useSelector((state) => state.vacancySearchDictionaries.orderBy);
    const searchPeriodDictionary = useSelector((state) => state.vacancySearchDictionaries.searchPeriod);
    const searchSettings = useSelector((state) => state.advancedVacancySearch.searchSettings?.statisticGroups);
    const selectedValues = useSelector((state) => state.advancedVacancySearch?.selectedValues);
    const partTimeDictionary = useSelector((state) => state.vacancySearchDictionaries.partTime);
    const itemsOnPageDictionary = Object.values(ItemsOnPageOption).map((value) => value);
    const isBackOffice = useSelector(({ userType }) => UserType.BackOffice === userType);
    const filteredPartTimeDictionary = partTimeDictionary.filter((item) => item !== PartTimeOption.TemporaryJobTrue);

    const isZP = useIsZarplataPlatform();

    const getTranslationsMap = (key: keyof typeof TrlKeys.filtersFromDictionaries) => {
        const filterTrl = TrlKeys.filtersFromDictionaries[key] as Record<string, string>;
        return Object.keys(filterTrl).reduce(
            (prev, currentValue) => ({
                ...prev,
                [currentValue]: trls[filterTrl[currentValue]],
            }),
            {}
        );
    };

    const getSelectedValue = (selectedValue: string[] | string | undefined, defaultValue = ''): string[] | string => {
        if (selectedValue) {
            if (Array.isArray(selectedValue)) {
                return selectedValue.length > 0 ? selectedValue : defaultValue;
            }
            return selectedValue;
        }
        return defaultValue;
    };
    const itemsOnPageTranslationsMap = getTranslationsMap(CriteriaKey.ItemsOnPage) as Record<string, string>;
    const itemsOnPageTranslations = Object.keys(itemsOnPageTranslationsMap).reduce((acc, cur) => {
        return { ...acc, [cur]: `${cur} ${itemsOnPageTranslationsMap[cur]}` };
    }, {});

    const searchSettingsDictionary = [];
    let searchSettingsSelectedValue = '';
    let searchSettingsTranslations = {};

    if (isBackOffice && searchSettings !== undefined) {
        for (let i = 0; i < searchSettings.length; i++) {
            if (searchSettings[i].selected) {
                searchSettingsSelectedValue = searchSettings[i].id;
            }
            searchSettingsDictionary.push(searchSettings[i].id);
            searchSettingsTranslations = {
                ...searchSettingsTranslations,
                [searchSettings[i].id]: searchSettings[i].name,
            };
        }
    }

    return (
        <Form ref={formRef} action={paths.vacancySearch} method="GET" data-qa="advanced-vacancy-search__form">
            <VacancyHiddenInputs />
            <Company />
            <Vacancy title={trls[TrlKeys.vacancyTitle]} />
            <Resume />
            <VacancySearchText />
            <VacancySearchExcludedText />
            <ProfessionalRole />
            <Industry />
            <Region noDistricts={isZP} noRelocation={true} />
            <Income />
            <RegularFilter
                name={CriteriaKey.Education}
                legend={trls[TrlKeys.legends[CriteriaKey.Education]]}
                inputs={educationDictionary}
                inputNames={getTranslationsMap(CriteriaKey.Education)}
                selectedValue={getSelectedValue(selectedValues?.[CriteriaKey.Education])}
            />
            <RegularFilter
                type={InputType.Radio}
                name={CriteriaKey.Experience}
                legend={trls[TrlKeys.legends[CriteriaKey.Experience]]}
                inputs={['doesNotMatter', ...experienceDictionary]}
                inputNames={getTranslationsMap(CriteriaKey.Experience)}
                selectedValue={getSelectedValue(selectedValues?.[CriteriaKey.Experience], 'doesNotMatter')}
            />
            <RegularFilter
                name={CriteriaKey.Employment}
                legend={trls[TrlKeys.legends[CriteriaKey.Employment]]}
                inputs={employmentDictionary}
                inputNames={getTranslationsMap(CriteriaKey.Employment)}
                selectedValue={getSelectedValue(selectedValues?.[CriteriaKey.Employment])}
                advancedItem={
                    <AcceptTemporary defaultChecked={selectedValues?.[CriteriaKey.AcceptTemporary] || false} />
                }
            />
            <RegularFilter
                name={CriteriaKey.Schedule}
                legend={trls[TrlKeys.legends[CriteriaKey.Schedule]]}
                inputs={scheduleDictionary}
                inputNames={getTranslationsMap(CriteriaKey.Schedule)}
                selectedValue={getSelectedValue(selectedValues?.[CriteriaKey.Schedule])}
            />
            <RegularFilter
                name={CriteriaKey.PartTime}
                legend={trls[TrlKeys.legends[CriteriaKey.PartTime]]}
                inputs={filteredPartTimeDictionary}
                inputNames={getTranslationsMap(CriteriaKey.PartTime)}
                selectedValue={getSelectedValue(selectedValues?.[CriteriaKey.PartTime])}
            />
            <RegularFilter
                name={CriteriaKey.Label}
                legend={trls[TrlKeys.legends[CriteriaKey.Label]]}
                inputs={labelDictionary}
                inputNames={getTranslationsMap(CriteriaKey.Label)}
                selectedValue={getSelectedValue(selectedValues?.[CriteriaKey.Label])}
            />
            <RegularFilter
                type={InputType.Radio}
                name={CriteriaKey.OrderBy}
                legend={trls[TrlKeys.legends[CriteriaKey.OrderBy]]}
                inputs={orderByDictionary}
                inputNames={getTranslationsMap(CriteriaKey.OrderBy)}
                selectedValue={getSelectedValue(selectedValues?.[CriteriaKey.OrderBy], OrderByOption.Relevance)}
            />
            <RegularFilter
                type={InputType.Radio}
                name={CriteriaKey.SearchPeriod}
                legend={trls[TrlKeys.legends[CriteriaKey.SearchPeriod]]}
                inputs={searchPeriodDictionary}
                inputNames={getTranslationsMap(CriteriaKey.SearchPeriod)}
                selectedValue={getSelectedValue(selectedValues?.[CriteriaKey.SearchPeriod], SearchPeriodOption.AllTime)}
            />
            <BOSearchPeriod />
            <RegularFilter
                type={InputType.Radio}
                name={CriteriaKey.ItemsOnPage}
                legend={trls[TrlKeys.legends[CriteriaKey.ItemsOnPage]]}
                inputs={itemsOnPageDictionary}
                inputNames={itemsOnPageTranslations}
                selectedValue={getSelectedValue(selectedValues?.[CriteriaKey.ItemsOnPage], ItemsOnPageOption.Fifty)}
            />
            <SubmitButton />
            {isBackOffice && (
                <>
                    {searchSettings !== undefined && (
                        <RegularFilter
                            type={InputType.Radio}
                            name="grouping"
                            legend={trls[TrlKeys.legends.searchSettings]}
                            inputs={searchSettingsDictionary}
                            inputNames={searchSettingsTranslations}
                            selectedValue={searchSettingsSelectedValue}
                        />
                    )}
                    <BOStatistics formRef={formRef} />
                </>
            )}
            <HhtmFromHiddenInput />
        </Form>
    );
};

export default translation(AdvancedVacancySearch);
