import { useCallback, useMemo } from 'react';

import { ChipsContainer, CustomChip, AdditionalDefault, TreeModel } from '@hh.ru/magritte-ui';

import { useCompositeSelectionContext } from 'src/components/CompositeSelection/contexts';

interface CompositeSelectionChipsProps {
    containerDataQa: string;
}

const CompositeSelectionChips: React.FC<CompositeSelectionChipsProps> = ({ containerDataQa }) => {
    const { collection, value, onChange } = useCompositeSelectionContext();

    const models = useMemo(() => {
        return value.reduce<TreeModel<AdditionalDefault>[]>((acc, curr) => {
            const model = collection.getModel(curr);

            if (model) {
                acc.push(model);
            }

            return acc;
        }, []);
    }, [collection, value]);

    const handleDelete = useCallback(
        (id: string) => {
            onChange(value.filter((item) => item !== id));
        },
        [value, onChange]
    );

    if (models.length === 0) {
        return null;
    }

    return (
        <ChipsContainer correction={8} data-qa={containerDataQa}>
            {models.map((model) => (
                <CustomChip key={model.id} Element="button" onDelete={() => handleDelete(model.id)}>
                    {model.text}
                </CustomChip>
            ))}
        </ChipsContainer>
    );
};

export default CompositeSelectionChips;
