import { useMemo, useState } from 'react';

import { Cell, CellText, Checkbox, Input, Link, Text, VSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AdvancedFilter from 'src/components/AdvancedSearchFilters/Magritte/AdvancedFilter';
import formatter from 'src/components/Forms/formatter';
import SuggestWithDefaultErrorPlaceholder from 'src/components/SuggestWithDefaultErrorPlaceholder';
import useSendSuggestAnalytics from 'src/components/SupernovaSearch/SmartSuggest/useSendAnalytics';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';
import { SearchField } from 'src/models/vacancySearch/vacancySearchDictionaries';
import { buildDataProvider, getAutosuggestItems } from 'src/utils/suggest/buildDataProvider';

const TrlKeys = {
    fieldTitle: 'vacancySearch.keywords',
    placeholder: 'vacancySearch.keywords.placeholder',
    example: 'vacancySearch.textSearchExample.0',
    queryLangDescr: 'employer.resumesSearch.queryLangDescr',
    randomExampleText: {
        1: 'vacancySearch.textSearchExample.1',
        2: 'vacancySearch.textSearchExample.2',
        3: 'vacancySearch.textSearchExample.3',
        4: 'vacancySearch.textSearchExample.4',
        5: 'vacancySearch.textSearchExample.5',
    },
    searchByField: {
        title: 'vacancySearch.searchByFields',
    },
    searchFields: {
        [SearchField.Name]: 'vacancySearch.searchByFields.name',
        [SearchField.Description]: 'vacancySearch.searchByFields.description',
        [SearchField.CompanyName]: 'vacancySearch.searchByFields.company_name',
    },
};

const DATA_QA_SEARCH_FIELD = 'control-vacancysearch__search_field-item';
const VACANCY_SUGGEST = '/vacancysuggest/';

const VacancySearchText: TranslatedComponent = ({ trls }) => {
    const { queryText, randomExampleNumber, searchFields } = useSelector(({ advancedVacancySearch }) => ({
        queryText: advancedVacancySearch.selectedValues[CriteriaKey.Text],
        randomExampleNumber: advancedVacancySearch.randomExampleNumber,
        searchFields: advancedVacancySearch.selectedValues.searchFields,
    }));
    const searchFieldsDictionary = useSelector(
        ({ vacancySearchDictionaries }) => vacancySearchDictionaries.searchFields
    );

    const [suggestText, setSuggestText] = useState(queryText || '');

    const dataProvider = useMemo(() => buildDataProvider(VACANCY_SUGGEST, '', formatter, getAutosuggestItems), []);

    const { sendSuggestShownAnalytics, sendSuggestItemClickAnalytics } = useSendSuggestAnalytics();

    const onSelectValidator = (value: string) => {
        if (value) {
            sendSuggestItemClickAnalytics({
                text: value,
            });
            setSuggestText(value);
        }
        return true;
    };

    return (
        <AdvancedFilter legend={trls[TrlKeys.fieldTitle]}>
            <SuggestWithDefaultErrorPlaceholder
                inputValue={suggestText}
                onSelectValidator={onSelectValidator}
                dataProvider={dataProvider}
                onDropOpen={sendSuggestShownAnalytics}
                input={{
                    component: Input,
                    props: {
                        name: CriteriaKey.Text,
                        placeholder: trls[TrlKeys.placeholder],
                        clearable: true,
                        autoComplete: 'off',
                        onChange: setSuggestText,
                        'data-qa': 'vacancysearch__keywords-input',
                    },
                }}
                navigationBarProps={{
                    title: trls[TrlKeys.fieldTitle],
                }}
            />
            <VSpacing default={8} />
            <Text Element="span" style="secondary" typography="label-3-regular">{`${trls[TrlKeys.example]} `}</Text>
            <Link
                Element="button"
                onClick={() => {
                    setSuggestText(trls[TrlKeys.randomExampleText[randomExampleNumber]]);
                }}
                typography="label-3-regular"
                inline
            >
                {trls[TrlKeys.randomExampleText[randomExampleNumber]]}
            </Link>
            <VSpacing default={24} />
            <VSpacingContainer default={12}>
                <Text>{trls[TrlKeys.searchByField.title]}</Text>
                {Object.values(searchFieldsDictionary)?.map((field) => (
                    <Cell
                        key={field}
                        align="center"
                        left={
                            <Checkbox
                                name="search_field"
                                value={field}
                                data-qa={`${DATA_QA_SEARCH_FIELD} ${DATA_QA_SEARCH_FIELD}_${field}`}
                                defaultChecked={searchFields?.includes(field)}
                            />
                        }
                    >
                        <CellText>{trls[TrlKeys.searchFields[field]]}</CellText>
                    </Cell>
                ))}
                <Link target="_blank" href="/article/1175">
                    {trls[TrlKeys.queryLangDescr]}
                </Link>
            </VSpacingContainer>
        </AdvancedFilter>
    );
};

export default translation(VacancySearchText);
