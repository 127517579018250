import { FC, ReactNode, PropsWithChildren } from 'react';

import { GridColumn, GridRow, Text, VSpacing } from '@hh.ru/magritte-ui';

interface AdvancedFilterProps {
    legend: ReactNode;
}

const AdvancedFilter: FC<AdvancedFilterProps & PropsWithChildren> = ({ legend, children }) => {
    return (
        <>
            <GridRow data-qa="advanced-filter">
                <GridColumn xs={4} s={8} m={2}>
                    <Text typography="subtitle-1-semibold">{legend}</Text>
                    <VSpacing default={12} gteM={0} />
                </GridColumn>
                <GridColumn xs={4} s={8} m={6}>
                    {children}
                </GridColumn>
            </GridRow>
            <VSpacing default={40} />
        </>
    );
};

export default AdvancedFilter;
