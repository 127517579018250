import Checkbox from 'bloko/blocks/checkbox';
import FormItem from 'bloko/blocks/form/FormItem';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import AdvancedFilter from 'src/components/AdvancedSearchFilters/AdvancedFilter';

const TrlKeys = {
    fieldTitle: 'clusters.similar.resume',
};

const Resume: TranslatedComponent = ({ trls }) => {
    const resume = useSelector(({ advancedSearch }) => advancedSearch.resume);
    if (!resume) {
        return null;
    }

    return (
        <AdvancedFilter legend={trls[TrlKeys.fieldTitle]}>
            <FormItem baseline>
                <Checkbox
                    name="resume"
                    value={resume.hash}
                    defaultChecked={true}
                    data-qa="vacancysearch__similar-resume-item"
                >
                    {resume.title}
                </Checkbox>
            </FormItem>
        </AdvancedFilter>
    );
};

export default translation(Resume);
