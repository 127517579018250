import { ComponentProps, FC } from 'react';

import Chips from 'src/components/CompositeSelection/components/Chips';
import TreeSelectorModal from 'src/components/CompositeSelection/components/TreeSelectorModal';
import { CompositeSelectionContextProvider } from 'src/components/CompositeSelection/contexts';

const CompositeSelection: FC<ComponentProps<typeof CompositeSelectionContextProvider>> = (props) => {
    const { collection, value, onChange, children } = props;

    return (
        <CompositeSelectionContextProvider collection={collection} value={value} onChange={onChange}>
            {children}
        </CompositeSelectionContextProvider>
    );
};

export default {
    Root: CompositeSelection,
    TreeSelectorModal,
    Chips,
};
