import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';

import { TreeCollection, fromTree, AdditionalDefault } from '@hh.ru/magritte-ui';

interface CompositeSelectionContextValues {
    collection: TreeCollection<AdditionalDefault>;
    value: string[];
    isTreeSelectorModalVisible: boolean;
    setTreeSelectorModalVisibility: Dispatch<SetStateAction<boolean>>;
    onChange: (value: string[]) => void;
}

export type CompositeSelectionContextProps = Omit<
    CompositeSelectionContextValues,
    'isTreeSelectorModalVisible' | 'setTreeSelectorModalVisibility'
>;

const CompositeSelectionContext = createContext<CompositeSelectionContextValues>({
    collection: fromTree([]),
    value: [],
    isTreeSelectorModalVisible: false,
    setTreeSelectorModalVisibility: () => null,
    onChange: () => null,
});

export const CompositeSelectionContextProvider: React.FC<CompositeSelectionContextProps & React.PropsWithChildren> = (
    props
) => {
    const [isTreeSelectorModalVisible, setTreeSelectorModalVisibility] = useState(false);

    const value = useMemo(
        () => ({
            collection: props.collection,
            value: props.value,
            isTreeSelectorModalVisible,
            setTreeSelectorModalVisibility,
            onChange: props.onChange,
        }),
        [props.collection, props.value, props.onChange, isTreeSelectorModalVisible]
    );

    return <CompositeSelectionContext.Provider value={value}>{props.children}</CompositeSelectionContext.Provider>;
};

export const useCompositeSelectionContext = (): CompositeSelectionContextValues =>
    useContext<CompositeSelectionContextValues>(CompositeSelectionContext);
