import { FC, Fragment, PropsWithChildren } from 'react';

import { VSpacing as MagritteVSpacing } from '@hh.ru/magritte-ui';
import Column from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';

import AdsExternalBanner from 'src/components/Banner/AdsExternalBanner';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { BannerPlace } from 'src/models/banners';

const RightBanners: FC<{ isMagritte?: boolean } & PropsWithChildren> = ({ isMagritte }) => {
    const rightBanners = useSelector(({ banners }) => banners[BannerPlace.RIGHT_COLUMN]);

    if (isMagritte) {
        return (
            <>
                <MagritteVSpacing default={0} gteM={56} />
                {rightBanners?.map((banner) => (
                    <Fragment key={banner.cId}>
                        <MagritteVSpacing default={24} />
                        <AdsExternalBanner {...banner} />
                    </Fragment>
                ))}
            </>
        );
    }

    return (
        <div className="banner-place-frame">
            <Column xs="4" s="8" m="4" l="4">
                {rightBanners?.map((banner) => (
                    <div key={banner.cId} className="banner-place-fat-wrapper">
                        <AdsExternalBanner {...banner} />
                        <VSpacing base={6} />
                    </div>
                ))}
            </Column>
        </div>
    );
};

export default translation(RightBanners);
