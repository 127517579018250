import { Cell, CellText, Checkbox } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AdvancedFilter from 'src/components/AdvancedSearchFilters/Magritte/AdvancedFilter';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    fieldTitle: 'clusters.similar.resume',
};

const Resume: TranslatedComponent = ({ trls }) => {
    const resume = useSelector(({ advancedSearch }) => advancedSearch.resume);

    if (!resume) {
        return null;
    }

    const { hash, title } = resume;

    return (
        <AdvancedFilter legend={trls[TrlKeys.fieldTitle]}>
            <Cell
                align="center"
                left={
                    <Checkbox name="resume" value={hash} defaultChecked data-qa="vacancysearch__similar-resume-item" />
                }
            >
                <CellText>{title}</CellText>
            </Cell>
        </AdvancedFilter>
    );
};

export default translation(Resume);
