import { useCallback, useMemo, useState } from 'react';

import { filterUniqueLeavesOnly, fromTree } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ActivatorWithChips from 'src/components/AdvancedSearchFilters/Magritte/ActivatorWithChips';
import AdvancedFilter from 'src/components/AdvancedSearchFilters/Magritte/AdvancedFilter';
import CompositeSelection from 'src/components/CompositeSelection';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';

const TrlKeys = {
    fieldTitle: 'resume.advancedSearch.field.professional_role',
    add: 'resume.advancedSearch.field.professional_role.add',
    edit: 'resume.advancedSearch.field.professional_role.edit',
};

const ProfessionalRole: TranslatedComponent = ({ trls }) => {
    const professionalRoleTree = useSelector(({ professionalRoleTree }) => professionalRoleTree);

    const selectedValues = useSelector(({ professionalRolesFromSearch }) => professionalRolesFromSearch);

    const [checkedValues, setCheckedValues] = useState<string[]>(selectedValues || []);

    const collection = useMemo(() => fromTree(professionalRoleTree.items), [professionalRoleTree]);

    const changeRoles = useCallback(
        (selectedItems: string[]) => {
            const topLvl = collection.getTopLevel().map(({ id }) => id);
            const newRoles = selectedItems.filter((item) => !topLvl.includes(item));
            setCheckedValues(newRoles);
        },
        [collection]
    );

    return (
        <AdvancedFilter legend={trls[TrlKeys.fieldTitle]}>
            <CompositeSelection.Root collection={collection} value={checkedValues} onChange={changeRoles}>
                <CompositeSelection.TreeSelectorModal
                    treeFilter={filterUniqueLeavesOnly}
                    texts={{
                        title: trls[TrlKeys.fieldTitle],
                    }}
                />
                <ActivatorWithChips
                    texts={{
                        add: trls[TrlKeys.add],
                        edit: trls[TrlKeys.edit],
                    }}
                    activatorDataQa="resumesearch__profroles-switcher"
                    chipsDataQa="selected-profroles"
                />
            </CompositeSelection.Root>
            {checkedValues.map((id) => (
                <input key={id} type="hidden" name={CriteriaKey.ProfessionalRole} value={id} />
            ))}
        </AdvancedFilter>
    );
};

export default translation(ProfessionalRole);
