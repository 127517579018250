import CompositeSelection from 'bloko/blocks/compositeSelection';
import { FormItem } from 'bloko/blocks/form';
import { SuggestLayer } from 'bloko/blocks/suggest';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { fromTree } from 'bloko/common/tree/treeCollectionHelper';

import translation from 'src/components/translation';
import { useAreasDataProvider } from 'src/hooks/useAreasDataProvider';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    title: 'employer.resumesSearch.region',
    submit: 'treeselector.save',
    cancel: 'treeselector.cancel',
    notFound: 'treeselector.notFound',
    placeholder: 'treeselector.quicksearch',
};

const Area: TranslatedComponent<{
    checkedValues: string[];
    onChange: (values: string[]) => void;
    autoInvite?: boolean;
}> = ({ trls, checkedValues, onChange, autoInvite }) => {
    const dataProvider = useAreasDataProvider(true);
    const areaTree = useSelector((state) => state.areaTree);
    const areaTreeCollection = fromTree(areaTree);

    return (
        <FormItem baseline>
            <CompositeSelection
                collection={areaTreeCollection}
                value={checkedValues}
                onChange={onChange}
                title={trls[TrlKeys.title]}
                trl={{
                    submit: trls[TrlKeys.submit],
                    cancel: trls[TrlKeys.cancel],
                    searchPlaceholder: trls[TrlKeys.placeholder],
                    notFound: trls[TrlKeys.notFound],
                }}
            >
                {({ renderTagList, renderInput }) => (
                    <>
                        {renderTagList({ removable: true })}
                        {renderInput({
                            suggest: true,
                            suggestProps: {
                                dataProvider,
                                // eslint-disable-next-line react/display-name
                                itemContent: (item) => <span data-qa="suggester__keywords-item">{item.text}</span>,
                                layer: autoInvite ? SuggestLayer.AboveOverlayContent : SuggestLayer.AboveContent,
                            },
                            'data-qa': 'advanced-search-region-add',
                            iconLinkProps: {
                                'data-qa': 'advanced-search-region-selectFromList',
                            },
                        })}
                    </>
                )}
            </CompositeSelection>
        </FormItem>
    );
};

export default translation(Area);
