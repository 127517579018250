import { useState } from 'react';

import { Input } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AdvancedFilter from 'src/components/AdvancedSearchFilters/Magritte/AdvancedFilter';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';

const TrlKeys = {
    placeholder: 'novaFilters.excludedText',
    fieldTitle: 'clusters.excluded_text',
};

const VacancySearchExcludedText: TranslatedComponent = ({ trls }) => {
    const queryText = useSelector(
        ({ advancedVacancySearch }) => advancedVacancySearch.selectedValues[CriteriaKey.ExcludedText]
    );
    const [text, setText] = useState(queryText || '');

    return (
        <AdvancedFilter legend={trls[TrlKeys.fieldTitle]}>
            <Input
                name={CriteriaKey.ExcludedText}
                value={text}
                onChange={setText}
                placeholder={trls[TrlKeys.placeholder]}
                autoComplete="off"
                data-qa="vacancysearch__keywords-excluded-input"
            />
        </AdvancedFilter>
    );
};

export default translation(VacancySearchExcludedText);
