import { useCallback, useState } from 'react';

import { Button, HSpacingContainer, Input, useBreakpoint } from '@hh.ru/magritte-ui';
import { BarsOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useCompositeSelectionContext } from 'src/components/CompositeSelection/contexts';
import SuggestWithDefaultErrorPlaceholder from 'src/components/SuggestWithDefaultErrorPlaceholder';
import translation from 'src/components/translation';
import { AreaSuggest, useAreasMagritteDataProvider } from 'src/hooks/useAreasDataProvider';

const TrlKeys = {
    fieldTitle: 'employer.resumesSearch.region',
    placeholder: 'advancedSearch.areaSuggest.placeholder',
    ariaLabel: 'advancedSearch.areaButton.ariaLabel',
};

const AreaActivator: TranslatedComponent = ({ trls }) => {
    const [suggestText, setSuggestText] = useState('');

    const { isMobile } = useBreakpoint();

    const dataProvider = useAreasMagritteDataProvider({
        allowRegions: true,
        inputValue: suggestText,
    });

    const { value, onChange, setTreeSelectorModalVisibility } = useCompositeSelectionContext();

    const openModal = useCallback(() => {
        setTreeSelectorModalVisibility(true);
    }, [setTreeSelectorModalVisibility]);

    const onSelectValidator = (_: string, data?: AreaSuggest) => !!data?.id;

    const onChangeSuggest = (_: string, isValueFromList: boolean, data?: AreaSuggest) => {
        if (isValueFromList && data) {
            setSuggestText('');
            onChange([...new Set([...value, data.id.toString()])]);
        }
    };

    if (isMobile) {
        return (
            <Input
                value={suggestText}
                placeholder={trls[TrlKeys.placeholder]}
                autoComplete="off"
                onChange={setSuggestText}
                data-qa="advanced-search-region-add"
                onClick={openModal}
                clearable
            />
        );
    }

    return (
        <HSpacingContainer default={12}>
            <SuggestWithDefaultErrorPlaceholder
                inputValue={suggestText}
                onChange={onChangeSuggest}
                onSelectValidator={onSelectValidator}
                dataProvider={dataProvider}
                input={{
                    component: Input,
                    props: {
                        placeholder: trls[TrlKeys.placeholder],
                        clearable: true,
                        autoComplete: 'off',
                        onChange: setSuggestText,
                        'data-qa': 'advanced-search-region-add',
                    },
                }}
                navigationBarProps={{
                    title: trls[TrlKeys.fieldTitle],
                }}
            />
            <Button
                mode="secondary"
                onClick={openModal}
                icon={<BarsOutlinedSize24 />}
                data-qa="advanced-search-region-selectFromList"
                aria-label={trls[TrlKeys.ariaLabel]}
                hideLabel
            />
        </HSpacingContainer>
    );
};

export default translation(AreaActivator);
