import { Cell, CellText, Checkbox } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AdvancedFilter from 'src/components/AdvancedSearchFilters/Magritte/AdvancedFilter';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    fieldTitle: 'clusters.similar.vacancy',
};

const Vacancy: TranslatedComponent = ({ trls }) => {
    const vacancy = useSelector(({ advancedSearch }) => advancedSearch.vacancy);

    if (!vacancy) {
        return null;
    }

    const { vacancyId, name } = vacancy;

    return (
        <AdvancedFilter legend={trls[TrlKeys.fieldTitle]}>
            <Cell
                align="center"
                left={
                    <Checkbox
                        name="vacancy_id"
                        value={vacancyId}
                        defaultChecked
                        data-qa="vacancysearch__vacancy-item"
                    />
                }
            >
                <CellText>{name}</CellText>
            </Cell>
        </AdvancedFilter>
    );
};

export default translation(Vacancy);
